import React from 'react'
import { Link } from 'react-router-dom'

import './EventCard.css'

function EventCard({ id, name, slug }) {
  return (
    <Link to={`events/${slug}`} className='dashCard' key={id}>
        <div className="dashCardLevel">
            <h2>{name}</h2>
        </div>

    </Link>
  )
}

export default EventCard