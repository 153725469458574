import React from 'react'
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";

import logoWithTagline from '../../assets/logoWithTagline.svg'
import mec from '../../assets/mec.png'

import './Footer.css'

function Footer() {
  return (
    <div className='footer'>
        <div className='footer_excel'>
            <a href='https://www.excelmec.org/' target='_blank' rel="noreferrer">
                <img src={logoWithTagline} alt='' className='footer_img'/>
            </a>
        </div>
        <div className='footer_social'>
            <div className='social_icons'>
                <a href='https://www.facebook.com/excelmec/' target='_blank' rel="noreferrer">
                    <FiFacebook className='footer_icon'/>
                </a>
                <a href='https://www.instagram.com/excelmec/' target='_blank' rel="noreferrer">
                    <FiInstagram className='footer_icon'/>
                </a>
                <a href='https://twitter.com/excelmec' target='_blank' rel="noreferrer">
                    <FiTwitter className='footer_icon'/>
                </a>
                <a href='https://www.linkedin.com/company/excelmec' target='_blank' rel="noreferrer">
                    <FiLinkedin className='footer_icon'/>
                </a>
            </div>
            <h2>Made with 💜 Excel 2022</h2>
        </div>
        <div className='footer_mec'>
            <a href='http://www.mec.ac.in/' target='_blank' rel="noreferrer">
                <img src={mec} alt='' className='footer_img'/>
            </a>
        </div>
    </div>
  )
}

export default Footer