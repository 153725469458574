import React from 'react'
import { useParams, Link } from 'react-router-dom';
import { BsGlobe } from "react-icons/bs";
import { FiHome } from "react-icons/fi";
import { Helmet } from 'react-helmet';

import eventData from '../../data/eventData'

import './EventPage.css'

function EventPage() {

    const { slug } = useParams()

    const filteredEvent = eventData && eventData.filter(x => x.slug === slug);
    const event = filteredEvent[0]
    
    return (
        <div className='eventPage'>
            <Helmet>
                <title>Excel 2022 | {event.name}</title>
            </Helmet>
            <Link to='/'>
                <FiHome className='go_home'/>
            </Link>
            <div className='eventPage__container'>
                <div className='eventPage__header'>
                    <div className='eventPage__details'>
                        <h1 className='event__name'>{event.name}</h1>
                        <p className='event__desc'>{event.desc}</p>
                    </div>
                    <div className='eventPage__poster'>
                        <img src={event.poster}  alt="" />

                        {event.website && (
                            <a href={event.website} target='_blank' rel="noreferrer" className='event_url'>
                                <BsGlobe />
                                <span>
                                    {event.website}
                                </span>
                            </a>
                        )}
                    </div>
                </div>
                <div className='eventPage__gallery'>
                    <h1>Gallery</h1>
                    <div className='eventGallery'>
                        {event.images.map((image, id) => (
                            <img key={id} src={image} alt="" className='eventImg'/>
                        ))}
                    </div>
                </div>
            </div>
            
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
        </div>
    )
}

export default EventPage