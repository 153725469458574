import React from 'react'
import Helmet from 'react-helmet'

import './HomePage.css'

import eventData from '../../data/eventData'

import EventCard from '../../components/EventCard/EventCard';


function HomePage() {
  return (
    <div className="dashboard">
      <Helmet>
        <title>Excel 2022 | Logo Launch</title>
      </Helmet>
      <div className="dashboard_header">
        <p className="story_desc">
          Excel is the annual national level techno-managerial fest of Govt. Model Engineering College. Conceived in 2001 by the students, Excel has grown exponentially, providing a strong platform which hosts technical, managerial and general events. These events are scheduled over three action packed days. Apart from a series of high profile events like IBeTo (Innovations for a Better Tomorrow), Untitled and .ISSUE!, Excel conducts online competitions, workshops, talks and seminars, video conferences, exhibitions and pro shows 
        </p>
      </div>
      <div className="dashboard_body">
        {eventData && eventData.length > 0 && eventData.map((eve) =>
          <EventCard
            key={eve.id}
            id={eve.id}
            name={eve.name}
            slug={eve.slug}
          />
        )}
      </div>


      <div id='stars'></div>
      <div id='stars2'></div>
      <div id='stars3'></div>
    </div>
  )
}

export default HomePage