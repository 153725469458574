import { 
    ibeto_poster, ceo_poster, hft_poster, issue_poster, summit_poster, meclabs_poster,
    ibeto1, ibeto2, issue1, issue2, issue3, hft1, hft2, hft3, meclabs1, meclabs2,
    summit1, summit2, summit3, summit4, summit5, ceo1, ceo2
} from './images'

const eventsData = [
    {
        id: 1,
        slug: 'issue',
        name: '.Issue',
        desc: '.ISSUE! is a platform where experienced veterans and budding youngsters come together to express their ideas, speak their minds and to challenge their views on the most pertinent issues that plague our society.',
        images: [issue1, issue2, issue3],
        poster: issue_poster,
        website: ''
    },
    {
        id: 2,
        slug: 'hft',
        name: 'Hack For Tomorrow',
        desc: "HackForTomorrow is a 24-hr product development competition focused on innovation. Participants are encouraged to think outside the box and bring about socially relevant solutions to today's problems from governance to revolutionizing energy. This event allows participants to team up with other bright minds and tackle some of the biggest problems we face today.",
        images: [hft1, hft2, hft3],
        poster: hft_poster,
        website: 'https://hft.excelmec.org/'
    },
    {
        id: 3,
        slug: 'mec-labs',
        name: 'MEC Labs',
        desc: "MECLABS is where you think out of the box, build better hardware, create faster software, and find the most innovative solutions to unanswered problems. Gather a team of four, build your very own project and display it for the world to see. Mentors are available anytime help is needed. Brainstorm and come up with the best of ideas you can lay your hands on and start experimenting, ideating and innovating. Creativity is the key to success.",
        images: [meclabs1, meclabs2],
        poster: meclabs_poster,
        website: ''
    },
    {
        id: 4,
        slug: 'engineering-summit',
        name: 'Engineering Summit',
        desc: 'Engineering Summit is a summit that combines every facet of engineering together and shows the student the myriad of ways Engineering can be used to uplift society. It removes the partitions set up by the different branches of subjects that separate each student of each stream and lets them experience the other aspects of engineering not taught in academia.',
        images: [summit1, summit2, summit3, summit4, summit5],
        poster: summit_poster,
        website: ''
    },
    {
        id: 5,
        slug: 'ibeto',
        name: 'Ibeto',
        desc: 'Innovations for a Better Tomorrow (IBeTo) is a technical innovations competition hosted by Govt. Model Engineering College, Kochi as part of their annual national-level technical symposium, Excel 2021. It is a national-level pursuit for socially relevant and technologically innovative ideas with the potential to trigger the next big social change. IBeTo gives the right platform and mentorship to launch the most technically innovative and revolutionary ideas and to develop them into a final product. IBeTo bridges the gap between innovators and society.',
        images: [ibeto1, ibeto2],
        poster: ibeto_poster,
        website: 'https://ibeto.excelmec.org/'
    },
    {
        id: 6,
        slug: 'ceo',
        name: 'CEO',
        desc: 'An event designed to bring out the manager within you, C.E.O tests your leadership skills, communication and teamwork through an interesting assortment of tasks.',
        images: [ceo1, ceo2],
        poster: ceo_poster,
        website: ''
    },
]

export default eventsData