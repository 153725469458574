import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';

import ScrollTotop from './utils/ScrollToTop'
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import HomePage from "./pages/HomePage/HomePage";
import EventPage from "./pages/EventPage/EventPage";

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <ScrollTotop />
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/events/:slug" element={<EventPage />} />
          
          <Route path="*" element={<Navigate to='/' />} />

        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
