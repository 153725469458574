import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'

import excel2021 from '../../assets/logos/excel2021.png'
import excel2020 from '../../assets/logos/excel2020.png'
import excel2019 from '../../assets/logos/excel2019.png'
import excel2018 from '../../assets/logos/excel2018.png'
import excel2017 from '../../assets/logos/excel2017.png'
import excel2016 from '../../assets/logos/excel2016.png'
import excel2015 from '../../assets/logos/excel2015.png'

function Navbar() {
  return (
    <div className='navbar'>
        <div className='nav-container'>
            <Link to='/' className='nav__text'>EXCEL 2022</Link>

            <div className='nav__logos'>
                <a href='https://2015.excelmec.org/#/' target='_blank' rel='noreferrer'>
                    <img src={excel2015} alt='' className='nav_excel_logo l2015'/>
                </a>
                <a href='https://2016.excelmec.org/#/' target='_blank' rel='noreferrer'>
                    <img src={excel2016} alt='' className='nav_excel_logo l2016'/>
                </a>
                <a href='https://2017.excelmec.org/#/' target='_blank' rel='noreferrer'>
                    <img src={excel2017} alt='' className='nav_excel_logo l2017'/>
                </a>
                <a href='https://2018.excelmec.org' target='_blank' rel='noreferrer'>
                    <img src={excel2018} alt='' className='nav_excel_logo l2018'/>
                </a>
                <a href='https://2019.excelmec.org' target='_blank' rel='noreferrer'>
                    <img src={excel2019} alt='' className='nav_excel_logo l2019'/>
                </a>
                <a href='https://2020.excelmec.org' target='_blank' rel='noreferrer'>
                    <img src={excel2020} alt='' className='nav_excel_logo l2020'/>
                </a>
                <a href='https://excelmec.org/' target='_blank' rel='noreferrer'>
                    <img src={excel2021} alt='' className='nav_excel_logo l2021'/>
                </a>
            </div>
        </div>
    </div>
  )
}

export default Navbar